import { ColorPicker, Input, Select } from "../../../../../../components";
import React, { useMemo } from "react";
import {
  StyledContainer,
  StyledItem,
  StyledRow,
} from "./editor-control-stroke-styled";

import EditorControlItemContainer from "../root/itemContainer";
import { Icon } from "../../../../../../components";

const EditorControlTextView = ({ items, selectedItem, onChange, onRemove }) => {
  const handleChangeValue = ({ value, key }) => {
    onChange({
      key,
      type: "style",
      value,
    });
  };

  const handleAdd = () => {
    console.log("handleAdd");

    onChange(
      {
        type: "style",
        value: {
          borderWidth: 1,
          borderColor: "rgba(0,0,0,1)",
        },
      },
      true
    );
  };

  const handleRemove = () => {
    onRemove({
      keys: ["borderWidth", "borderColor"],
      type: "style",
    });
  };

  return (
    <EditorControlItemContainer
      title="Stroke"
      icon={
        <Icon
          name={!items[selectedItem].style.borderWidth ? "plus" : "minus"}
          size={12}
          onClick={() =>
            !items[selectedItem].style.borderWidth
              ? handleAdd()
              : handleRemove()
          }
        />
      }
      content={
        items[selectedItem].style.borderWidth && (
          <>
            <StyledRow>
              <StyledItem>
                <ColorPicker
                  value={items[selectedItem].style.borderColor || "#000"}
                  onChange={(value) =>
                    handleChangeValue({
                      value,
                      key: "borderColor",
                    })
                  }
                />
              </StyledItem>
            </StyledRow>

            <StyledRow>
              <StyledItem style={{ width: "40%" }}>
                <Input
                  value={items[selectedItem].style.borderWidth || 1}
                  icon={require("./image/borderWith.svg")}
                  type="number"
                  onChange={(value) =>
                    handleChangeValue({
                      value,
                      key: "borderWidth",
                    })
                  }
                />
              </StyledItem>
            </StyledRow>
          </>
        )
      }
    />
  );
};

export default EditorControlTextView;
