import React, { useEffect } from "react";

import EditorContentItem from "../item";

import { StyledContainer, StyledWrraper } from "./editor-content-block-styled";

import { useValidStyle } from "../../../../../../hook";

// import Hammer from "hammerjs";

const EditorContentImageView = ({
  selected,
  style,
  containerStyle,
  onMouseDown
}) => {
  const validStyle = useValidStyle(style);

  // useEffect(() => {
  //   var myElement = document.getElementById("myElement");

  //   var mc = new Hammer.Manager(myElement);

  //   // create a pinch and rotate recognizer
  //   // these require 2 pointers
  //   var pinch = new Hammer.Pinch();
  //   var rotate = new Hammer.Rotate();

  //   // we want to detect both the same time
  //   pinch.recognizeWith(rotate);

  //   // add to the Manager
  //   mc.add([pinch, rotate]);

  //   mc.on("pinch rotate", function(ev) {
  //     myElement.textContent += ev.type + " ";
  //   });
  // }, []);

  return (
    <EditorContentItem
      selected={selected}
      style={containerStyle}
      onMouseDown={onMouseDown}
    >
      <StyledContainer style={validStyle} />
    </EditorContentItem>
  );
};

export default EditorContentImageView;
