import React, { useEffect, useMemo, useState } from "react";
import {
  StyledColor,
  StyledContainer,
  StyledItem,
  StyledValue,
} from "./color-picker-styled";

import Input from "../input";

const InputView = ({ value, label, type = "string", onChange }) => {
  const rgb2hex = (rgb) => {
    rgb = rgb.match(
      /^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i
    );
    return rgb && rgb.length === 4
      ? "#" +
          ("0" + parseInt(rgb[1], 10).toString(16)).slice(-2) +
          ("0" + parseInt(rgb[2], 10).toString(16)).slice(-2) +
          ("0" + parseInt(rgb[3], 10).toString(16)).slice(-2)
      : "";
  };

  const tansformValueColor = () => {
    if (value.indexOf("#") != -1) {
      return value.slice(1);
    } else {
      let hex = rgb2hex(value);

      return hex.slice(1);
    }
  };

  const tansformedValueColor = useMemo(() => {
    return tansformValueColor();
  }, [value]);

  const tansformedValueAlpha = useMemo(() => {
    if (value.indexOf("#") != -1) {
      return 100;
    } else {
      let values = value.split(",");

      let percent = values[values.length - 1].slice(0, -1);

      console.log("percent", percent);

      return percent * 100;
    }
  }, [value]);

  const [localValueColor, setLocalValueColor] = useState(tansformedValueColor);
  const [localValueAlpha, setLocalValueAlpha] = useState(tansformedValueAlpha);

  useEffect(() => {
    if (localValueColor != value) {
      setLocalValueColor(tansformValueColor);
      setLocalValueAlpha(tansformedValueAlpha);
    }
  }, [value]);

  const handleChangeValue = () => {
    let rgba = hexToRgba(`#${localValueColor}`, localValueAlpha);

    if (rgba && rgba != value) onChange(rgba);
  };

  useEffect(() => {
    handleChangeValue();
  }, [localValueColor, localValueAlpha]);

  const hexToRgba = (hex, alpha) => {
    console.log("hex", hex);

    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");

      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");

      return (
        "rgba(" +
        [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
        "," +
        alpha / 100 +
        ")"
      );
    }
  };

  return (
    <StyledContainer>
      <StyledColor color={localValueColor} />

      <StyledItem>
        <Input
          style={{ border: "none" }}
          value={localValueColor}
          defaultValue={tansformedValueColor}
          onChange={setLocalValueColor}
        />
      </StyledItem>

      <Input
        style={{ border: "none" }}
        value={localValueAlpha}
        defaultValue={tansformedValueAlpha}
        type="number"
        min={0}
        max={100}
        onChange={setLocalValueAlpha}
      />
    </StyledContainer>
  );
};

export default InputView;
