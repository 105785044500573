import React, { useMemo } from "react";

import EditorHeaderScaleView from "./editor-header-scale-view";

import { options } from "./editor-header-scale-config";

const EditorHeaderScaleContainer = props => {
  const renderOptions = useMemo(() => {
    const _options = [...options];

    if (Math.floor(props.defaultScale * 100) % 10 !== 0) {
      _options.push({
        value: props.scale,
        text: `${Math.floor(props.scale * 100)}%`
      });
    }

    return _options;
  }, [props.defaultScale]);

  return <EditorHeaderScaleView {...props} options={renderOptions} />;
};

export default EditorHeaderScaleContainer;
