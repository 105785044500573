import styled from "styled-components";

export const StyledContainer = styled.div`
  padding: 15px 0;

  // margin-bottom: 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  border-bottom: 1px solid #e5e5e5;
`;

export const StyleHeader = styled.div`
  width: 100%;

  margin: 0;
  padding: 0 15px;

  display: flex;
  justify-content: space-between;

  opacity: ${(props) => (props.active ? 1 : 0.5)};
`;

export const StyleContent = styled.div`
  width: 100%;
  padding: 0 5px;
`;

export const StyledTitle = styled.p`
  font-weight: 700;
  font-size: 11px;
  color: #333;
`;

export const StyledIcon = styled.div`
  transition: all 0.2s;

  &:hover {
    transition: all 0.2s;
    opacity: 0.5;
  }
`;
