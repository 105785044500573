import styled from "styled-components";

export const StyledContainer = styled.div`
  padding: 0 5px;
  width: 100%;

  flex: 1;
  display: flex;
  align-items: center;

  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
`;

export const StyledColor = styled.div`
  margin-right: 5px;

  height: 16px;
  width: 16px;

  border-radius: 1px;
  overflow: hidden;

  background-color: ${(props) => `#${props.color}`};

  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
`;

export const StyledValue = styled.input`
  // margin-left: 10px;
  width: 100%;

  border: none;

  line-height: 13px;
  font-size: 11px;

  &:hover,
  &:focus {
    outline: none;
    border: none;
  }
`;

export const StyledItem = styled.div`
  width: 50%;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
`;
