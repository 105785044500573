import {
  canvasSelector,
  historyStackSelector,
  itemsSelector,
  selectedItemSelector,
} from "../../../../modules/editor/selectors";
import {
  saveCanvas,
  saveHistoryStack,
  saveItems,
  saveSelectedItem,
} from "../../../../modules/editor/actions";

import EditorContentContainer from "./editor-control-container";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
  items: itemsSelector(state),
  selectedItem: selectedItemSelector(state),
  canvas: canvasSelector(state),
  historyStack: historyStackSelector(state),
});

const mapDispatchToProps = {
  saveItems,
  saveCanvas,
  saveSelectedItem,
  saveHistoryStack,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditorContentContainer);
