import React, { createRef, useEffect } from "react";

import { Dimmer, Loader } from "semantic-ui-react";
import { NotificationContainer } from "react-notifications";

import EditorEmpty from "./components/empty";

import {
  StyledBody,
  StyledContainer,
  StyledHeader,
  StyledWrapper,
  StyledControl
} from "./editor-styled";

import EditorContent from "./components/content";
import EditorControl from "./components/control";
import EditorHeader from "./components/header";

export default function EditorView({ loading, success }) {
  return (
    <StyledContainer>
      <Dimmer active={loading}>
        <Loader />
      </Dimmer>

      {loading || success ? (
        <>
          <StyledHeader>
            <EditorHeader />
          </StyledHeader>

          <StyledWrapper>
            <StyledBody>
              {!loading && <EditorContent />}

              <StyledControl>
                <EditorControl />
              </StyledControl>
            </StyledBody>
          </StyledWrapper>
        </>
      ) : (
        <EditorEmpty />
      )}

      <NotificationContainer />
    </StyledContainer>
  );
}
