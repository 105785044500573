import EditorContainer from "./editor-container";

import { connect } from "react-redux";

import {
  uploadSelector,
  loadingSelector,
  successSelector
} from "../../modules/editor/selectors";

import { loadEditorData } from "../../modules/editor/actions";

const mapStateToProps = state => ({
  upload: uploadSelector(state),
  loading: loadingSelector(state),
  success: successSelector(state)
});

const mapDispatchToProps = {
  loadEditorData
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditorContainer);
