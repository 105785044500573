import styled from "styled-components";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;

  border: none;
  outline: none;

  overflow: hidden;
`;

export const StyledHeader = styled.div`
  width: 100%;
`;

export const StyledBody = styled.div`
  display: flex;
  height: 100%;
`;

export const StyledWrapper = styled.div`
  flex: 1;

  overflow: hidden;
`;

export const StyledControl = styled.div`
  margin-left: auto;
  height: 100%;
  display: flex;
`;

// transform: scale(0.16574074074074074);
