import styled from "styled-components";

export const StyledContainer = styled.div`
  position: relative;
`;

export const StyledWrapper = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

export const StyledImage = styled.img`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  ${props => props.color && `tint-color: ${props.color}`};
  color: #b3b3b3;
`;
