import { StyledContainer, StyledWrraper } from "./editor-content-image-styled";

import EditorContentItem from "../item";
import React from "react";
import { useValidStyle } from "../../../../../../hook";

const EditorContentImageView = ({
  src,
  scale,
  selected,
  style,
  containerStyle,
  onMouseDown,
  onClick
}) => {
  const validStyle = useValidStyle(style);
  // const validContainerStyle = useValidStyle(containerStyle);

  return (
    <EditorContentItem
      selected={selected}
      style={containerStyle}
      onMouseDown={onMouseDown}
    >
      <StyledContainer onClick={onClick}>
        <img src={src} style={validStyle} alt="img" />

        <StyledWrraper />
      </StyledContainer>
    </EditorContentItem>
  );
};

export default EditorContentImageView;
