import { ColorPicker, Input, Select } from "../../../../../../components";
import React, { useMemo } from "react";
import {
  StyledContainer,
  StyledItem,
  StyledRow,
} from "./editor-control-fill-styled";

import EditorControlItemContainer from "../root/itemContainer";

const EditorControlTextView = ({ items, selectedItem, canvas, onChange }) => {
  const handleChangeValue = (value) => {
    onChange({
      key: "backgroundColor",
      type: "containerStyle",
      value,
    });
  };

  return (
    <EditorControlItemContainer
      title="Background"
      content={
        <StyledRow>
          <StyledItem>
            <ColorPicker
              value={items[selectedItem].containerStyle.backgroundColor}
              onChange={handleChangeValue}
            />
          </StyledItem>
        </StyledRow>
      }
    />
  );
};

export default EditorControlTextView;
