import * as Actions from "./constants";

export const initState = {
  // loading: true,
  loading: false,
  saved: true,
  success: true,

  defaultScale: 1,
  scale: 1,

  canvas: {
    width: 5400,
    height: 1149,
    backgroundColor: "#fff",
    url: null,
    type: "horizontal",
    countColumn: 1
  },

  items: {
    // 1: { id: 1, type: "text", containerStyle: {}, style: {}, value: "hello" }
    //
  },

  selectedItem: null,

  historyStack: [],

  upload: {
    loading: false,
    success: null
  }
};

/**
 * Common reducer
 * @param state
 * @param action
 * @returns {*}
 */
function commonReducer(state = initState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case Actions.LOAD_EDITOR_DATA:
      return { ...state, loading: true, success: null };
    case Actions.LOAD_EDITOR_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        items: payload.template ? payload.template.items : state.items,
        canvas: {
          ...(payload.template ? payload.template.canvas : state.canvas)
        }
      };
    case Actions.LOAD_EDITOR_DATA_ERROR:
      return { ...state, loading: false, success: false };

    case Actions.SAVE_SAVED:
      return { ...state, saved: payload };

    case Actions.INIT_SCALE:
      return { ...state, scale: payload, defaultScale: payload };
    case Actions.SAVE_SCALE:
      return { ...state, scale: payload };

    case Actions.SAVE_ITEMS:
      return { ...state, items: payload, saved: false };

    case Actions.SAVE_CANVAS:
      return { ...state, canvas: payload };

    case Actions.SAVE_HISTORY_STACK:
      console.log("SAVE_HISTORY_STACK", payload);

      return { ...state, historyStack: payload };

    case Actions.REMOVE_ITEM:
      let deletedRes = { ...state.items };

      if (deletedRes[payload].type !== "image") delete deletedRes[payload];
      else deletedRes[payload] = null;

      return {
        ...state,
        items: {
          ...deletedRes
        }
      };

    case Actions.SAVE_SELECTED_ITEM:
      return { ...state, selectedItem: payload };

    case Actions.UPLOAD_TEMPLATE:
      return {
        ...state,
        upload: {
          ...state.upload,
          loading: true,
          success: null
        }
      };
    case Actions.UPLOAD_TEMPLATE_SUCCESS:
      console.log("payload", { payload });

      return {
        ...state,
        saved: true,
        items: payload.items,
        canvas: payload.canvas,
        // saved: true,
        upload: {
          ...state.upload,
          loading: false,
          success: true
        }
      };
    case Actions.UPLOAD_TEMPLATE_ERROR:
      return {
        ...state,
        upload: {
          ...state.upload,
          loading: false,
          success: false
        }
      };

    default:
      return state;
  }
}

export default commonReducer;
