import EditorContentContainer from "./editor-content-container";

import { connect } from "react-redux";

import {
  scaleSelector,
  itemsSelector,
  selectedItemSelector,
  canvasSelector,
  savedSelector
} from "../../../../modules/editor/selectors";
import {
  initScale,
  saveScale,
  saveItems,
  removeItem,
  saveSelectedItem,
  saveSaved
} from "../../../../modules/editor/actions";

const mapStateToProps = state => ({
  scale: scaleSelector(state),
  items: itemsSelector(state),
  selectedItem: selectedItemSelector(state),
  canvas: canvasSelector(state),
  saved: savedSelector(state)
});

const mapDispatchToProps = {
  initScale,
  saveScale,
  saveItems,
  removeItem,
  saveSelectedItem,
  saveSaved
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditorContentContainer);
