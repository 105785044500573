import styled from "styled-components";

export const StyledContainer = styled.div`
  position: relative;
`;

export const StyledWrraper = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;
