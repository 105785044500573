// import styled from "styled-components";

// export const StyledContainer = styled.div`
//   position: relative;
//   /* overflow: hidden; */
// `;

// // width: 100 %;
// // position: absolute;
// // top: 0px;
// // left: 0px;
// // text - align: center;
// export const StyledTextarea = styled.textarea`
//   position: absolute;
//   top: 0;
//   left: 0;
//   /* bottom: 0;
//   right: 0; */
//   /* // top: -${props => props.fontSize / 38}px;
//   // left: -${props => props.fontSize / 38}px;
//   // top: -2px;
//   // left: -2px;
//   // max-height: 100%; */
//   height: 100%;
//   min-width: 10px;
//   padding: 0px !important;
//   /* padding: 50px;  */
//   margin: 0px;
//   /* text-shadow: 1px 1px 2px black, 0 0 1em red; */
//   /* // padding-right: 10%; */

//   box-sizing: border-box;
//   /* // text-align: center; */
//   color: inherit;
//   font-family: inherit;
//   text-transform: inherit;
//   font-weight: inherit;
//   text-align: inherit;
//   line-height: inherit;
//   background: transparent;

//   outline: none;
//   border: none;
//   resize: none;
//   user-select: none;
//   z-index: 1;
//   /* overflow: hidden; */
// `;

// export const StyledText = styled.p`
//   margin: 0px;
//   padding: 0px;
//   /* // text-align: start; */
//   text-align: center;
//   white-space: pre-line;
//   /* padding-right: 20px; */

//   /* // opacity: ${props => (props.show ? 1 : 0)}; */

//   /* overflow: hidden; */
// `;

// export const StyledWrapper = styled.div`
//   position: absolute;
//   top: 0px;
//   right: 0px;
//   bottom: 0px;
//   left: 0px;
// `;

import styled from "styled-components";

export const StyledContainer = styled.div`
  position: relative;
  overflow: hidden;
`;

// width: 100 %;
// position: absolute;
// top: 0px;
// left: 0px;
// text - align: center;
export const StyledTextarea = styled.textarea`
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  min-width: 10px;
  padding: inherit;
  margin: 0px;

  box-sizing: border-box;
  color: inherit;
  font-family: inherit;
  text-transform: inherit;
  font-weight: inherit;
  text-align: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  background: transparent;

  outline: none;
  border: none;
  resize: none;
  user-select: none;
  overflow: hidden;
`;

export const StyledText = styled.p`
  margin: 0px;
  padding: 0px;
  text-align: center;
  white-space: pre-line;
  /* padding-right: 20px; */

  overflow: hidden;
`;

export const StyledWrapper = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;
