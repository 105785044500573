import EditorContentItem from "../item";
import React from "react";
import { StyledContainer } from "./editor-content-background-styled";

const EditorContentImageView = ({
  url,
  backgroundColor,
  selected,
  inside,
  onMouseDown,
}) => {
  return (
    <>
      <EditorContentItem
        selected={selected}
        inside={inside}
        style={{
          width: "100%",
          height: "100%",
          backgroundColor,
          backgroundImage: `url(${url})`,
          backgroundRepeat: "norepeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        onMouseDown={onMouseDown}
      ></EditorContentItem>
    </>
  );
};

export default EditorContentImageView;
