import React, { useState } from "react";

import EditorContentItemView from "./editor-content-item-view";

const EditorContentItemContainer = props => {
  const [hovered, setHovered] = useState(false);

  return (
    <EditorContentItemView
      {...props}
      hovered={hovered}
      changeHovered={setHovered}
    />
  );
};

export default EditorContentItemContainer;
