import styled from "styled-components";

export const StyledContainer = styled.div`
  display: flex;
  // padding-right: 12px;
  justify-content: space-between;

  background: #2c2c2c;

  cursor: default;
  user-select: none;
`;

export const StyledGroup = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledButton = styled.div`
  margin-right: 12px;
`;
