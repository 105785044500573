import PropTypes from "prop-types";

import React from "react";

import {
  StyledContainer,
  StyledContent,
  StyledBorder
} from "./editor-content-item-styled";

const EditorContentItemView = ({
  hovered,
  children,
  selected,
  style,
  inside,
  changeHovered,
  onMouseDown
}) => {
  return (
    <StyledContainer
      style={{ ...style }}
      // transform: "rotate(50deg)"
      selected={selected}
      inside={inside}
      onMouseDown={onMouseDown}
      onMouseEnter={() => !inside && changeHovered(true)}
      onMouseLeave={() => !inside && changeHovered(false)}
      // onMouseUp={() => onSetActiveItem(null)}
    >
      <StyledContent>{children}</StyledContent>
      {(selected || hovered) && (
        <>
          <StyledBorder
            selected={selected}
            hovered={hovered}
            inside={inside}
            position="top"
            horizontal={true}
          ></StyledBorder>
          <StyledBorder
            selected={selected}
            hovered={hovered}
            inside={inside}
            position="bottom"
            horizontal={true}
          ></StyledBorder>
          <StyledBorder
            selected={selected}
            hovered={hovered}
            inside={inside}
            position="right"
            horizontal={false}
          ></StyledBorder>

          <StyledBorder
            selected={selected}
            hovered={hovered}
            inside={inside}
            position="left"
            horizontal={false}
          ></StyledBorder>
        </>
      )}
    </StyledContainer>
  );
};

EditorContentItemView.propTypes = {
  style: PropTypes.object,
  inside: PropTypes.bool
};

EditorContentItemView.defaultProps = {
  style: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0
  },
  inside: false
};

export default EditorContentItemView;
