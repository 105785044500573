import { all } from "redux-saga/effects";
import editorSaga from "./modules/editor/saga";

/**
 * Root saga
 * @returns {IterableIterator<AllEffect | GenericAllEffect<any> | *>}
 */
export default function* rootSagas() {
  // yield all([]);
  yield all([editorSaga()]);
}
