import React, { useState } from "react";

import EditorContentTextView from "./editor-content-text-view";

const EditorContentTextContainer = props => {
  const [editing, setEditing] = useState(false);

  const handlerKeyPress = e => {
    e.stopPropagation();

    // console.log(props.id);
  };

  const handlerEdit = value => {
    setEditing(value);
    props.onChangeDisabledItem(value);
  };

  const handlerChange = event => {
    const { value, id, items, saveItems } = props;

    console.log(value);

    // console.log(event.target.value);

    saveItems({
      ...items,
      [id]: {
        ...items[id],
        value: event.target.value
      }
    });
  };

  return (
    <EditorContentTextView
      {...props}
      editing={editing}
      onEdit={handlerEdit}
      onKeyPress={handlerKeyPress}
      onChange={handlerChange}
    />
  );
};

export default EditorContentTextContainer;
