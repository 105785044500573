import {
  ColorPicker,
  FileLoader,
  Icon,
  Input,
  Select,
} from "../../../../../../components";
import React, { useMemo } from "react";
import {
  StyledContainer,
  StyledContent,
  StyledFileLoad,
  StyledItem,
  StyledRow,
} from "./editor-control-image-styled";

import { Button } from "semantic-ui-react";
import EditorControlItemContainer from "../root/itemContainer";

const EditorControlTextView = ({ items, selectedItem, canvas, onChange }) => {
  const handleChangeValue = (data) => {
    onChange([
      {
        key: selectedItem == null ? "url" : "src",
        value: data.url,
      },
      {
        key: "file",
        value: data.file,
      },
      selectedItem != null && {
        key: "backgroundColor",
        type: "style",
        value: "rgba(255,255,255,0)",
      },
    ]);
  };

  const handleClear = (data) => {
    onChange({
      key: selectedItem == null ? "url" : "src",
      value: null,
    });
  };

  return (
    <EditorControlItemContainer
      title="Image"
      content={
        <StyledRow>
          <StyledItem>
            <FileLoader
              onClick={handleChangeValue}
              close={
                selectedItem == null &&
                canvas.url && <Icon name="close" onClick={handleClear} />
              }
            >
              <Button size="tiny">Load image</Button>
            </FileLoader>
          </StyledItem>
        </StyledRow>
      }
    />
  );
};

export default EditorControlTextView;
