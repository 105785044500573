import { Icon, Input } from "../../../../../../../../components";
import React, { useEffect, useMemo, useState } from "react";
import {
  StyledContainer,
  StyledIcon,
  StyledLabel,
  StyledValue,
} from "./editor-control-container-item-styled";

const EditorHeaderItemView = ({
  name,
  label,
  value,
  tag,
  type = "number",
  onChange,
}) => {
  const transformValue = (value) => {
    if (value && typeof value == "string" && value.indexOf("rotate") != -1) {
      if (value) return value.slice(7, -4);
      else return value;
    } else return value;
  };

  // const [localValue, setLocalValue] = useState(transformValue(value));

  // useEffect(() => {
  //   if (localValue != value) setLocalValue(transformValue(value));
  // }, [value]);

  const handleChangeValue = (value) => {
    console.log("res", {
      key: name,
      type: tag,
      value: name == "transform" ? `rotate(${value}deg)` : value,
    });
    if (name == "zIndex")
      onChange(
        {
          type: tag,
          value: {
            zIndex: value,
            elevation: value,
          },
        },
        true
      );
    else
      onChange({
        key: name,
        type: tag,
        value: name == "transform" ? `rotate(${value}deg)` : value,
      });
  };

  return (
    <StyledContainer>
      <Input
        icon={label.icon && require(`./image/${label.icon}.svg`)}
        label={label.text}
        value={value}
        defaultValue={transformValue(value)}
        type={type}
        transformValue={transformValue}
        onChange={handleChangeValue}
      />
    </StyledContainer>
  );
};

export default EditorHeaderItemView;
