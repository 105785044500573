import styled from "styled-components";

export const StyledContainer = styled.div`
  padding: 5px 15px;
  // min-width: 25%;

  flex: 1;
  display: flex;
  align-items: center;

  border: 1px solid #fff;

  // &: hover {
  //   border: 1px solid rgba(0, 0, 0, 0.1);
  // }
`;

export const StyledLabel = styled.p`
  margin: 0;
  margin-right: 10px;

  font-size: 13px;
  line-height: 13px;
  color: #b3b3b3;
`;

export const StyledValue = styled.input`
  height: 13px;

  border: none;

  font-size: 13px;
  line-height: 13px;

  &:hover,
  &:focus {
    outline: none;
    border: none;
  }
`;
