import React, { useMemo } from "react";
import { StyledContainer, StyledItem } from "./editor-control-uploader-styled";
import {
  containerStyle,
  elements,
  renderContainer,
} from "../../../../../../config/elements";

import EditorControlItemContainer from "../root/itemContainer";
import { Input } from "../../../../../../components";

const EditorControlContainerView = ({
  items,
  selectedItem,
  canvas,
  onChange,
}) => {
  const data = ["left", "top", "width", "height"];
  const numberValuesElements = ["width", "height"];

  return (
    <EditorControlItemContainer
      title="Uploader"
      content={
        <StyledContainer>
          {data.map((key) => (
            <StyledItem>
              <Input
                label={renderContainer[key].text}
                value={items[selectedItem].uploader[key] || "auto"}
                defaultValue={["left", "top"].indexOf(key) != -1 ? "auto" : 0}
                type={
                  numberValuesElements.indexOf(key) != -1 ? "number" : "string"
                }
                onChange={(value) =>
                  onChange({
                    key,
                    type: "uploader",
                    value: value,
                  })
                }
              />
            </StyledItem>
          ))}
        </StyledContainer>
      }
    />
  );
};

export default EditorControlContainerView;
