import styled from "styled-components";

export const StyledContainer = styled.div`
  display: flex;
`;

export const StyledContent = styled.div`
  display: flex;

  border: 1px solid #fff;
  border-radius: 3px;

  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

export const StyledItem = styled.div`
  // padding: 3px 5px;

  width: 24px;
  height: 24px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${(props) =>
    props.active ? "rgba(0, 0, 0, 0.1)" : "#fff"};

  cursor: pointer;
`;
