export const options = [
  { value: 1, text: `100%` },
  { value: 0.9, text: `90%` },
  { value: 0.8, text: `80%` },
  { value: 0.7, text: `70%` },
  { value: 0.6, text: `60%` },
  { value: 0.5, text: `50%` },
  { value: 0.4, text: `40%` },
  { value: 0.3, text: `30%` },
  { value: 0.2, text: `20%` },
  { value: 0.1, text: `10%` }
];
