import { ColorPicker, Input, Select } from "../../../../../../components";
import React, { useMemo } from "react";
import {
  StyledContainer,
  StyledItem,
  StyledRow,
} from "./editor-control-effects-styled";

import EditorControlItemContainer from "../root/itemContainer";
import { Icon } from "../../../../../../components";

const textShadow = {
  shadowColor: "textShadowColor",
  shadowOffset: "textShadowOffset",
  shadowRadius: "textShadowRadius",
  elevation: "elevation",
};

const EditorControlTextView = ({ items, selectedItem, onChange, onRemove }) => {
  const isText = useMemo(() => {
    return items[selectedItem].type == "text";
  }, [items, selectedItem]);

  const active = useMemo(() => {
    return items[selectedItem].style[
      isText ? "textShadowOffset" : "shadowOffset"
    ];
  }, [items, selectedItem]);

  const handleChangeValue = ({ value, key, subKey }) => {
    console.log("key", key);
    console.log("handleChangeValue", {
      key: isText ? textShadow[key] : key,
      subKey,
      type: selectedItem == null ? "canvas" : "style",
      value,
    });

    if (key != "")
      onChange(
        key == "elevation"
          ? {
              key: isText ? textShadow[key] : key,
              type: "containerStyle",
              value,
            }
          : {
              key: isText ? textShadow[key] : key,
              subKey,
              type: selectedItem == null ? "canvas" : "style",
              value,
            }
      );
  };

  const handleAdd = () => {
    console.log("handleAdd");

    onChange(
      [
        {
          type: "style",
          value: {
            [isText ? "textShadowColor" : "shadowColor"]: "rgba(0,0,0,0.25)",
            [isText ? "textShadowOffset" : "shadowOffset"]: {
              width: 0,
              height: 1,
            },
            [isText ? "textShadowRadius" : "shadowRadius"]: 1,
          },
        },
        {
          type: "containerStyle",
          value: {
            elevation: items[selectedItem].containerStyle.elevation || 1,
          },
        },
      ],
      true
    );
  };

  const handleRemove = () => {
    console.log("handleRemove");

    onRemove({
      keys: [
        "shadowColor",
        "shadowOffset",
        "shadowRadius",
        "textShadowColor",
        "textShadowOffset",
        "textShadowRadius",
      ],
      type: "style",
    });

    onChange([
      {
        key: "elevation",
        type: "containerStyle",
        value: 0,
      },
    ]);
  };

  return (
    <EditorControlItemContainer
      title="Effects"
      icon={
        <Icon
          name={!active ? "plus" : "minus"}
          size={12}
          onClick={() => (!active ? handleAdd() : handleRemove())}
        />
      }
      content={
        active && (
          <>
            <StyledRow>
              <StyledItem>
                <ColorPicker
                  value={
                    items[selectedItem].style[
                      isText ? "textShadowColor" : "shadowColor"
                    ] || "#000"
                  }
                  onChange={(value) =>
                    handleChangeValue({
                      value,
                      key: "shadowColor",
                    })
                  }
                />
              </StyledItem>
            </StyledRow>

            <StyledRow>
              <StyledItem>
                <Input
                  value={
                    items[selectedItem].style[
                      isText ? "textShadowOffset" : "shadowOffset"
                    ].width
                  }
                  label="X"
                  type="number"
                  onChange={(value) =>
                    handleChangeValue({
                      value,
                      key: "shadowOffset",
                      subKey: "width",
                    })
                  }
                />
              </StyledItem>

              <StyledItem>
                <Input
                  value={
                    items[selectedItem].style[
                      isText ? "textShadowOffset" : "shadowOffset"
                    ].height
                  }
                  label="Y"
                  type="number"
                  onChange={(value) =>
                    handleChangeValue({
                      value,
                      key: "shadowOffset",
                      subKey: "height",
                    })
                  }
                />
              </StyledItem>
            </StyledRow>

            <StyledRow>
              <StyledItem style={{ width: "70%" }}>
                <Input
                  value={
                    items[selectedItem].style[
                      isText ? "textShadowRadius" : "shadowRadius"
                    ]
                  }
                  label="R"
                  type="number"
                  onChange={(value) =>
                    handleChangeValue({
                      value,
                      key: "shadowRadius",
                    })
                  }
                />
              </StyledItem>

              <StyledItem>
                <Input
                  value={items[selectedItem].containerStyle.elevation}
                  label="Elevation"
                  type="number"
                  onChange={(value) =>
                    handleChangeValue({
                      value,
                      key: "elevation",
                    })
                  }
                />
              </StyledItem>
            </StyledRow>
          </>
        )
      }
    />
  );
};

export default EditorControlTextView;
