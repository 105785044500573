import { Input, Select } from "../../../../../../components";
import React, { useMemo } from "react";
import {
  StyledContainer,
  StyledItem,
  StyledRow
} from "./editor-control-text-styled";
import {
  containerStyle,
  elements,
  renderContainer,
  strValuesElements
} from "../../../../../../config/elements";
import {
  fontFamilyOptions,
  fontStyleOptions,
  fontWeigthOptions
} from "./options";

import EditoControlTextAlign from "./componets/align";
import EditorControlItemContainer from "../root/itemContainer";

const EditorControlTextView = ({ items, selectedItem, onChange }) => {
  const handleChangeValue = ({ value, key }) => {
    onChange({
      key,
      type: "style",
      value
    });
  };

  return (
    <EditorControlItemContainer
      title="Text"
      content={
        <>
          <StyledRow>
            <StyledItem>
              <Select
                value={items[selectedItem].style.fontFamily}
                search
                style={{ fontSize: 11.5 }}
                options={fontFamilyOptions}
                onChange={value =>
                  handleChangeValue({
                    value,
                    key: "fontFamily"
                  })
                }
              />
            </StyledItem>
          </StyledRow>

          <StyledRow>
            <StyledItem>
              <Select
                value={items[selectedItem].style.fontWeight}
                options={fontWeigthOptions}
                onChange={value =>
                  handleChangeValue({
                    value,
                    key: "fontWeight"
                  })
                }
              />
            </StyledItem>

            <StyledItem>
              <Select
                value={items[selectedItem].style.fontStyle}
                options={fontStyleOptions}
                onChange={value =>
                  handleChangeValue({
                    value,
                    key: "fontStyle"
                  })
                }
              />
            </StyledItem>
          </StyledRow>

          <StyledRow>
            <StyledItem>
              <Input
                value={items[selectedItem].style.fontSize}
                label="S"
                type="number"
                onChange={value => {
                  console.log("handleChangeValue", value);

                  handleChangeValue({
                    value,
                    key: "fontSize"
                  });
                }}
              />
            </StyledItem>

            <StyledItem>
              <EditoControlTextAlign
                value={items[selectedItem].style.textAlign}
                onChange={value =>
                  handleChangeValue({
                    value,
                    key: "textAlign"
                  })
                }
              />
            </StyledItem>
          </StyledRow>

          <StyledRow>
            <StyledItem>
              <Input
                value={items[selectedItem].style.lineHeight}
                icon={require("./image/lineHeight.svg")}
                iconSize={15}
                type="number"
                onChange={value =>
                  handleChangeValue({
                    value,
                    key: "lineHeight"
                  })
                }
              />
            </StyledItem>

            <StyledItem>
              <Input
                value={items[selectedItem].style.letterSpacing}
                icon={require("./image/lineHeight.svg")}
                iconSize={15}
                type="number"
                onChange={value =>
                  handleChangeValue({
                    value,
                    key: "letterSpacing"
                  })
                }
              />
            </StyledItem>
          </StyledRow>

          <StyledRow>
            <StyledItem>
              <Input
                value={items[selectedItem].style.paddingVertical}
                label="PH"
                type="number"
                onChange={value => {
                  console.log("handleChangeValue", value);

                  handleChangeValue({
                    value,
                    key: "paddingVertical"
                  });
                }}
              />
            </StyledItem>

            <StyledItem>
              <Input
                value={items[selectedItem].style.paddingHorizontal}
                label="PW"
                type="number"
                onChange={value => {
                  console.log("handleChangeValue", value);

                  handleChangeValue({
                    value,
                    key: "paddingHorizontal"
                  });
                }}
              />
            </StyledItem>
          </StyledRow>
        </>
      }
    />
  );
};

export default EditorControlTextView;
