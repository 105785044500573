import { applyMiddleware, compose, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";

import AsyncStorage from "@react-native-community/async-storage";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./reducers";
import rootSaga from "./sagas";

const persistConfig = {
  key: "root",
  storage: AsyncStorage,
  whitelist: [],
};

const composeEnhancers =
  process.env.NODE_ENV === "development"
    ? composeWithDevTools({ realtime: true })
    : compose;

const sagaMiddleware = createSagaMiddleware();

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default () => {
  const enhancer = composeEnhancers(applyMiddleware(sagaMiddleware));

  const store = createStore(persistedReducer, enhancer);

  let persistor = persistStore(store);

  // then run the saga
  sagaMiddleware.run(rootSaga);

  return { store, persistor };
};
