import styled from "styled-components";

export const StyledContainer = styled.div`
  margin: 3px 10px;
  min-width: 30%;

  flex: 1;
`;

export const StyledLabel = styled.p`
  margin: 0;

  line-height: 13px;
  font-size: 11px;
  color: #b3b3b3;
`;

export const StyledIcon = styled.div`
  height: 13px;
  width: 13px;
`;

export const StyledValue = styled.input`
  margin-left: 10px;
  width: 100%;

  border: none;

  line-height: 13px;
  font-size: 11px;

  &:hover,
  &:focus {
    outline: none;
    border: none;
  }
`;
