import * as Actions from "./constants";

export function loadEditorData(payload) {
  return {
    type: Actions.LOAD_EDITOR_DATA,
    payload
  };
}

export function initScale(payload) {
  return {
    type: Actions.INIT_SCALE,
    payload
  };
}

export function saveSaved(payload) {
  return {
    type: Actions.SAVE_SAVED,
    payload
  };
}

export function saveScale(payload) {
  return {
    type: Actions.SAVE_SCALE,
    payload
  };
}

export function saveItems(payload) {
  return {
    type: Actions.SAVE_ITEMS,
    payload
  };
}

export function saveCanvas(payload) {
  return {
    type: Actions.SAVE_CANVAS,
    payload
  };
}

export function saveHistoryStack(payload) {
  return {
    type: Actions.SAVE_HISTORY_STACK,
    payload
  };
}

export function saveSelectedItem(payload) {
  return {
    type: Actions.SAVE_SELECTED_ITEM,
    payload
  };
}

export function removeItem(payload) {
  return {
    type: Actions.REMOVE_ITEM,
    payload
  };
}

export function uploadTemplate(payload) {
  return {
    type: Actions.UPLOAD_TEMPLATE,
    payload
  };
}
