const typeOptions = [
  {
    text: "Post",
    value: "square"
  },
  {
    text: "Story",
    value: "story"
  },
  {
    text: "Vertical",
    value: "vertical"
  },
  {
    text: "Horizontal",
    value: "horizontal"
  }

  // {
  //   text: "Post",
  //   value: "post"
  // }
];

const countColumnOptions = [
  {
    text: "1",
    value: 1
  },
  {
    text: "2",
    value: 2
  },
  {
    text: "3",
    value: 3
  },
  {
    text: "4",
    value: 4
  },
  {
    text: "5",
    value: 5
  },
  {
    text: "6",
    value: 6
  },
  {
    text: "7",
    value: 7
  },
  {
    text: "8",
    value: 8
  }
];

export { typeOptions, countColumnOptions };
