import styled from "styled-components";

export const StyledContainer = styled.div`
  padding: 5px;
  width: 100%;

  flex: 1;
  display: flex;
  align-items: center;

  border: 1px solid #fff;
  border-radius: 3px;

  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  text {
    font-size: 9px;
  }
`;

export const StyledLabel = styled.p`
  margin: 0;

  line-height: 13px;
  font-size: 11px;
  color: #b3b3b3;
`;

export const StyledIcon = styled.div`
  height: 13px;
  width: 13px;
`;

export const StyledValue = styled.input`
  margin-left: 10px;
  width: 100%;

  border: none;

  line-height: 13px;
  font-size: 11px;

  &:hover,
  &:focus {
    outline: none;
    border: none;
  }
`;
