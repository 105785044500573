import { Icon, Select } from "../../../../../../components";
import { StyledContainer, StyledValue } from "./editor-header-item-styled";

import React from "react";

const EditorHeaderItemView = ({
  icon,
  value,
  options,
  style,
  onClick = () => {},
}) => {
  return (
    <StyledContainer onClick={() => !options && onClick()}>
      {icon && (
        <Icon
          style={{ height: 18, opacity: 0.8 }}
          s
          src={require(`../../image/${icon}.svg`)}
        />
      )}

      {options ? (
        <Select value={value} style={style} options={options} />
      ) : (
        // <select value={value}>
        //   {options.map(item => (
        //     <option value={item.value}>{item.label}</option>
        //   ))}
        // </select>
        value && <StyledValue>{value}%</StyledValue>
      )}
      {/* {value && <StyledValue>{value}</StyledValue>} */}
    </StyledContainer>
  );
};

export default EditorHeaderItemView;
