import React, { useMemo } from "react";
import {
  StyleContent,
  StyleHeader,
  StyledContainer,
  StyledIcon,
  StyledTitle,
} from "./editor-control-item-container-styled";

const EditorControlTextView = ({ title, icon, content }) => {
  return (
    <StyledContainer>
      <StyleHeader active={!!content}>
        <StyledTitle>{title}</StyledTitle>

        <StyledIcon>{icon}</StyledIcon>
      </StyleHeader>

      {!!content && <StyleContent>{content}</StyleContent>}
    </StyledContainer>
  );
};

export default EditorControlTextView;
