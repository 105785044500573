import PropTypes from "prop-types";

import React, { useEffect, useMemo, useState } from "react";
import {
  StyledContainer,
  StyledIcon,
  StyledLabel,
  StyledValue
} from "./select-styled";

import { Dropdown } from "semantic-ui-react";

const SelectView = ({
  value,
  placeholder,
  search = false,
  options,
  defaultValue,
  style,
  onChange
}) => {
  return (
    <StyledContainer
      style={style}
      as={Dropdown}
      placeholder={placeholder}
      search={search}
      options={options}
      value={value}
      defaultValue={defaultValue}
      onChange={(event, data) => onChange(data.value)}
    />
    // </StyledContainer>
  );
};

SelectView.propTypes = {
  onChange: PropTypes.func
};

SelectView.defaultProps = {
  onChange: () => {}
};

export default SelectView;
