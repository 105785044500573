import React, { useEffect, useState } from "react";

import EditorControlView from "./editor-control-view";
import { strValuesElements } from "../../../../config/elements";

const EditorContentContainer = ({
  items,
  selectedItem,
  canvas,
  historyStack,
  saveItems,
  saveCanvas,
  saveHistoryStack,
}) => {
  const handleChange = (data, isObject = false) => {
    let elemetns = data.length ? data : [data];
    let res = { ...items };

    console.log("elemetns", elemetns.length);
    console.log("selectedItem", selectedItem);

    // return;

    if (selectedItem != null) {
      for (let item of elemetns) {
        let { key, subKey, value, type } = item;

        let trasformValue =
          strValuesElements.indexOf(key) != -1 || value == "auto"
            ? value
            : +value;

        console.log("item", { key, subKey, value, type });

        if (!type && !isObject)
          res = {
            ...res,
            [selectedItem]: {
              ...res[selectedItem],
              [key]: value,
            },
          };
        else if (isObject)
          res = {
            ...res,
            [selectedItem]: {
              ...res[selectedItem],
              [type]: {
                ...res[selectedItem][type],
                ...value,
              },
            },
          };
        else if (subKey)
          res = {
            ...res,
            [selectedItem]: {
              ...res[selectedItem],
              [type]: {
                ...res[selectedItem][type],
                [key]: {
                  ...res[selectedItem][type][key],
                  [subKey]: trasformValue,
                },
              },
            },
          };
        else {
          res = {
            ...res,
            [selectedItem]: {
              ...res[selectedItem],
              [type]: {
                ...res[selectedItem][type],
                [key]: trasformValue,
              },
            },
          };
        }
      }

      saveItems({ ...res });
    } else {
      for (let item of elemetns) {
        let { key, value } = item;

        let trasformValue =
          strValuesElements.indexOf(key) != -1 ||
          value == "auto" ||
          key == "file" ||
          key == "type"
            ? value
            : +value;

        canvas = {
          ...canvas,
          [key]: trasformValue,
        };
      }

      console.log("canvas", canvas);

      saveCanvas({
        ...canvas,
      });
    }

    // saveHistoryStack([
    //   ...historyStack,
    //   {
    //     key,
    //     value,
    //     type,
    //     item: selectedItem,
    //   },
    // ]);
  };

  const handleRemove = ({ type, keys }) => {
    let res = { ...items };

    for (let key of keys) {
      delete res[selectedItem][type][key];
    }

    saveItems({ ...res });
  };

  return (
    <EditorControlView
      items={items}
      canvas={canvas}
      selectedItem={selectedItem}
      onChange={handleChange}
      onRemove={handleRemove}
    />
  );
};

export default EditorContentContainer;
