// import AsyncStorage from "@react-native-community/async-storage";
import { combineReducers } from "redux";
import editorReducer from "./modules/editor/reducer";
import authReducer from "./modules/auth/reducer";
// import { persistReducer } from "redux-persist";

/**
 * Root reducer
 * @type {Reducer<any> | Reducer<any, AnyAction>}
 */
const rootReducers = combineReducers({
  editor: editorReducer,
  auth: authReducer
});

export default rootReducers;
