import styled from "styled-components";

export const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  border: 1px solid #fff;
`;
