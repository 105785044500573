import styled from "styled-components";

export const StyledContainer = styled.div`
  // flex: 1;
  height: 100%;
  width: 100%;
  display: flex;
  // justify-content: center;
  // align-items: center;
  // width: 100%;
  // display: flex;
  // // justify-content: center;
  // // align-items: center;

  cursor: default;
  user-select: none;
  border: none;
  outline: none;

  overflow: auto;

  // background: green;
`;

export const StyledWrapper = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin: auto;
`;

export const StyledContent = styled.div`
  position: relative;
  // width: ${props => props.width}px;
  // width: 5400px;
  // height: ${props => props.height}px;
  // margin: 6px;
 
  background: #fff;

  overflow: hidden;

  // transform: scale(${props => props.scale}) translateX(-33.5%);
  //   translateX(${props => props.scale * 100 * 9}%)
  //   translateY(-${props => 100 - props.scale * 100}%);
  // margin-left: -${props => props.scale * 17}%;
`;
