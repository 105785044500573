export const LOAD_EDITOR_DATA = "editor/LOAD_EDITOR_DATA";
export const LOAD_EDITOR_DATA_SUCCESS = "editor/LOAD_EDITOR_DATA_SUCCESS";
export const LOAD_EDITOR_DATA_ERROR = "editor/LOAD_EDITOR_DATA_ERROR";

export const SAVE_SAVED = "editor/SAVE_SAVED";
export const SAVE_SCALE = "editor/SAVE_SCALE";
export const INIT_SCALE = "editor/INIT_SCALE";
export const SAVE_ITEMS = "editor/CHANGE_ITEMS";
export const SAVE_CANVAS = "editor/SAVE_CANVAS";
export const SAVE_HISTORY_STACK = "editor/SAVE_HISTORY_STACK";
export const SAVE_SELECTED_ITEM = "editor/CHANGE_SELECTED_ITEM";
export const REMOVE_ITEM = "editor/REMOVE_ITEM";

export const UPLOAD_TEMPLATE = "editor/UPLOAD_TEMPLATE";
export const UPLOAD_TEMPLATE_SUCCESS = "editor/UPLOAD_TEMPLATE_SUCCESS";
export const UPLOAD_TEMPLATE_ERROR = "editor/UPLOAD_TEMPLATE_ERROR";
