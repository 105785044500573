import styled from "styled-components";

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledFileLoad = styled.input`
  display: none;
`;
