import styled from "styled-components";

export const StyledContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url(${props => props.src});
  background-size: cover;
  background-repeat: repeat;
  /* background-color: ; */
`;

export const StyledUploader = styled.div`
  position: absolute;
  top: ${props => (props.top === "auto" ? "auto" : `${props.top}px`)};
  left: ${props => (props.left === "auto" ? "auto" : `${props.left}px`)};
  height: ${props => props.height}px;
  width: ${props => props.width}px;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: ${props => props.height / 2}px;

  background: #fff;

  // z-index: 998;
  opacity: 0.6;
`;

// ${props =>
//   (props.selected || props.activeItem) &&
//   `&:hover {
//   border: 3px solid blue;
// }`}
