import "./App.css";
import "react-notifications/lib/notifications.css";

import React, { useEffect } from "react";

// import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";

import { setHeader } from "./utils/request";

import RootRouter from "./router/root";

import configureStore from "./config-store";

const { store } = configureStore();

// setHeader(
//   "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiYmEwNjA2YjkyMmFhODJlZWRkMGIzNWVhOTdkNThlM2NlZGE0MDY1NTVhZWFhODc1ZmY3OTYwNGVlOGYxNGEyYTc1MzY4MWE3YWM1MmExMWMiLCJpYXQiOjE1OTI2ODM3OTYsIm5iZiI6MTU5MjY4Mzc5NiwiZXhwIjoxNjI0MjE5Nzk2LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.ZNMrwZCLr9PxpQ98PxLlH241AgaY7SpHNsNMMMswU4wcbIQFQUtWWISLuzTtnpw6DQdnR_HOPJWSaHZswF3PoJk9comS21xUMoAK7CoPZj8ioXamVA81lBFWHdYYH95i_jAzMzxX0edVw-Mg23EzFyybupgOKrmRVVnBQvcxWgXcLv-0WN0SMrxHLCm9rDf57-6377fNX_h9D22fHYD5SdvBqGnEVF-CKH0CDsOQBWx__k2vfmIaDYOkfVKgN9GYxJMQXvAjuAffYoWMForbhh7ixj5KmgLII3UPOA89r0osUOY1c9vHXPKEl2tDUTICKzRDCpOZ-bRvAlJ7rk-oRdmKlBG7564wT7Va9WxC7M6zi9Av_MaPZUwFrNlBxcma1J_mGEzSZ9P4rdEwvRtDmZY2GD5No9A4zt4G8zamuLPpc0d1qsRG-aRIHjPEgG9ACM-OXh73zescuaLM6XnV-9WkrRXedZRpLQ8MXZ40YKHOrsjUze0eGKAM4bHsZ4dUjOZ2Rt_Fsp7r8moH6fhvEKcpibODHQWEbcMWoBRf09AESlrYZ6mHiI-IrU6_hVcJOyvIXqYuw9hInW-TBheFw1JlQMTJpfws_fw4xKRjDTNGm_t__mEIJvcWP3yQADLtVW8ynLnnZftjGLpa3LnGfnjbrv5ZElO7bS_IwRn7bxw"
// );

function App() {
  // let query = new URLSearchParams(useLocation().search);

  // alert(JSON.stringify(query));

  // useEffect(() => {
  //   setHeader(
  //     "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiYmEwNjA2YjkyMmFhODJlZWRkMGIzNWVhOTdkNThlM2NlZGE0MDY1NTVhZWFhODc1ZmY3OTYwNGVlOGYxNGEyYTc1MzY4MWE3YWM1MmExMWMiLCJpYXQiOjE1OTI2ODM3OTYsIm5iZiI6MTU5MjY4Mzc5NiwiZXhwIjoxNjI0MjE5Nzk2LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.ZNMrwZCLr9PxpQ98PxLlH241AgaY7SpHNsNMMMswU4wcbIQFQUtWWISLuzTtnpw6DQdnR_HOPJWSaHZswF3PoJk9comS21xUMoAK7CoPZj8ioXamVA81lBFWHdYYH95i_jAzMzxX0edVw-Mg23EzFyybupgOKrmRVVnBQvcxWgXcLv-0WN0SMrxHLCm9rDf57-6377fNX_h9D22fHYD5SdvBqGnEVF-CKH0CDsOQBWx__k2vfmIaDYOkfVKgN9GYxJMQXvAjuAffYoWMForbhh7ixj5KmgLII3UPOA89r0osUOY1c9vHXPKEl2tDUTICKzRDCpOZ-bRvAlJ7rk-oRdmKlBG7564wT7Va9WxC7M6zi9Av_MaPZUwFrNlBxcma1J_mGEzSZ9P4rdEwvRtDmZY2GD5No9A4zt4G8zamuLPpc0d1qsRG-aRIHjPEgG9ACM-OXh73zescuaLM6XnV-9WkrRXedZRpLQ8MXZ40YKHOrsjUze0eGKAM4bHsZ4dUjOZ2Rt_Fsp7r8moH6fhvEKcpibODHQWEbcMWoBRf09AESlrYZ6mHiI-IrU6_hVcJOyvIXqYuw9hInW-TBheFw1JlQMTJpfws_fw4xKRjDTNGm_t__mEIJvcWP3yQADLtVW8ynLnnZftjGLpa3LnGfnjbrv5ZElO7bS_IwRn7bxw"
  //   );
  // }, []);

  return (
    <div className="App">
      {/* <PersistGate loading={null} persistor={persistor}> */}
      <Provider store={store}>
        <RootRouter />
      </Provider>
      {/* </PersistGate> */}
    </div>
  );
}

export default App;
