import EditorContentTextContainer from "./editor-content-text-container";

import { connect } from "react-redux";

import { itemsSelector } from "../../../../../../modules/editor/selectors";
import { saveItems } from "../../../../../../modules/editor/actions";

const mapStateToProps = state => ({
  items: itemsSelector(state)
});

const mapDispatchToProps = {
  saveItems
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditorContentTextContainer);
