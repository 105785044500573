import { useMemo } from "react";

export default (style, scale) => {
  // if (style.shadowOffset) console.log(style);
  return useMemo(() => {
    const res = { ...style };

    if (style.borderWidth) {
      res.border = `${style.borderWidth}px solid ${style.borderColor}`;
      res.borderWidth = undefined;
      res.borderColor = undefined;
    }

    if (style.shadowOffset) {
      res.boxShadow = `${style.shadowOffset.width}px ${style.shadowOffset.height}px ${style.shadowRadius}px ${style.shadowColor}`;
      res.shadowOffset = undefined;
      res.shadowRadius = undefined;
      res.shadowColor = undefined;
    }

    if (style.textShadowOffset) {
      res.textShadow = `1px 1px 2px black`;
      // res.textShadow = `${style.textShadowOffset.width}px ${style.textShadowOffset.height}px ${style.textShadowRadius}px ${style.textShadowColor}`;
      res.textShadowOffset = undefined;
      res.textShadowRadius = undefined;
      res.textShadowColor = undefined;
    }

    if (style.paddingVertical || style.paddingHorizontal) {
      console.log(style);
      res.padding = `${style.paddingVertical ||
        0}px ${style.paddingHorizontal || 0}px`;
      // res.padding = `${style.paddingHorizontal ||
      //   0}px ${style.paddingVertical || 0}px`;
    }

    // if (style.left) {
    //   res.transform = `translateX(${res.left}px) translateY(${res.top}px)`;
    //   res.top = 0;
    //   res.left = 0;
    // }

    return res;
  }, [scale, style]);
};

// Object.keys(style).forEach(key => {
//   const value = style[key];
//   switch (key) {
//     case "border":
//       console.log(value, key);
//       // res[key] = ``
//       break;
//     default:
//       res[key] = value;
//       break;
//   }
//   // res[key] =
//   //   typeof value === "number" ? Math.floor(value * scale) : value;
// });
