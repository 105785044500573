// import React, { useState, useMemo, useEffect, createRef } from "react";

// import EditorContentItem from "../item";

// import {
//   StyledContainer,
//   StyledTextarea,
//   StyledText,
//   StyledWrapper
// } from "./editor-content-text-styled";

// import { useValidStyle } from "../../../../../../hook";

// const EditorContentTextView = ({
//   value,
//   style,
//   editing,
//   selected,
//   containerStyle,
//   onMouseDown,
//   onClick,
//   onKeyPress,
//   onEdit,
//   onChange
// }) => {
//   const validStyle = useValidStyle(style);

//   const texareaRef = createRef();

//   // const [res, setRes] = useState(value);

//   const countRows = useMemo(() => {
//     // return res.split(/[\n]/).length;
//     return value.split(/[\n\s]/).length;
//   }, [value]);

//   const countColumn = useMemo(() => {
//     let maxCount = 1;

//     const rows = value.split("\n");

//     rows.forEach(row => {
//       if (row.length > maxCount) maxCount = row.length;
//     });

//     return maxCount;
//   }, [value]);

//   useEffect(() => {
//     if (editing) {
//       texareaRef.current.focus();
//       texareaRef.current.setSelectionRange(0, value.length);
//     }
//   }, [editing]);

//   const handlerMouseDown = e => {
//     onEdit(true);
//   };

//   // return (
//   //   <StyledTextarea
//   //     value={res}
//   //     rows={countRows}
//   //     cols={res.length + 10}
//   //     onChange={e => setRes(e.target.value)}
//   //     onBlur={() => onEdit(false)}
//   //   />
//   // );

//   return (
//     <EditorContentItem
//       selected={selected}
//       style={containerStyle}
//       onMouseDown={data => !editing && onMouseDown(data)}
//     >
//       {/* <pre style={{ fontSize: 50 }}>{JSON.stringify(validStyle)}</pre> */}
//       {/* <pre style={{ fontSize: 50 }}>{`${value}`}</pre> */}

//       <StyledContainer onDoubleClick={handlerMouseDown}>
//         <StyledText
//           style={{
//             ...validStyle,
//             textShadow: "0 0 1em red"
//           }}
//           // background: "green"
//         >
//           {/* <span style={{ opacity: !editing ? 1 : 0 }}>{res || `_`}</span> */}
//           {/* {res || `_`} */}
//           {/* textShadow: "1px 1px 2px black, 0 0 1em red", */}
//           <span
//             // style={{ opacity: 0 }}
//             // style={{ background: "green" }}
//             dangerouslySetInnerHTML={{
//               __html: value
//                 ? value.replace(/ /gi, "&nbsp;").replace(/\n$/, "\n&nbsp;")
//                 : "&nbsp;"
//             }}
//           />
//         </StyledText>

//         {editing && (
//           <StyledTextarea
//             ref={texareaRef}
//             value={value}
//             rows={countRows}
//             cols={countColumn}
//             autoFocus
//             // fontSize={style.fontSize}
//             style={validStyle}
//             // cols={res.length}
//             onKeyPress={onKeyPress}
//             // onChange={e => setRes(e.target.value)}
//             onBlur={() => onEdit(false)}
//             onChange={onChange}
//             // style={{ backgroundColor: "green" }}
//             // onChange={event => console.log(event.target.value)}
//           />
//         )}
//         {!editing && <StyledWrapper />}
//         {/* )} */}

//         {/* &nbsp; ewfef&nbsp; */}

//         {/* {res.length} */}
//       </StyledContainer>
//     </EditorContentItem>
//   );
// };

// export default EditorContentTextView;

import React, { useState, useMemo, useEffect, createRef } from "react";

import EditorContentItem from "../item";

import {
  StyledContainer,
  StyledTextarea,
  StyledText,
  StyledWrapper
} from "./editor-content-text-styled";

import { useValidStyle } from "../../../../../../hook";

const EditorContentTextView = ({
  value,
  style,
  editing,
  selected,
  containerStyle,
  onMouseDown,
  onClick,
  onKeyPress,
  onEdit,
  onChange
}) => {
  const validStyle = useValidStyle(style);

  const texareaRef = createRef();

  // const [res, setRes] = useState(value);

  const countRows = useMemo(() => {
    // return res.split(/[\n]/).length;
    return value.split(/[\n\s]/).length;
  }, [value]);

  const countColumn = useMemo(() => {
    let maxCount = 1;

    const rows = value.split("\n");

    rows.forEach(row => {
      if (row.length > maxCount) maxCount = row.length;
    });

    return maxCount;
  }, [value]);

  useEffect(() => {
    if (editing) {
      texareaRef.current.focus();
      texareaRef.current.setSelectionRange(0, value.length);
    }
  }, [editing]);

  const handlerMouseDown = e => {
    onEdit(true);
  };

  // return (
  //   <StyledTextarea
  //     value={res}
  //     rows={countRows}
  //     cols={res.length + 10}
  //     onChange={e => setRes(e.target.value)}
  //     onBlur={() => onEdit(false)}
  //   />
  // );

  return (
    <EditorContentItem
      selected={selected}
      style={containerStyle}
      onMouseDown={data => !editing && onMouseDown(data)}
    >
      <StyledContainer>
        <StyledText style={validStyle} onDoubleClick={handlerMouseDown}>
          {/* <span style={{ opacity: !editing ? 1 : 0 }}>{res || `_`}</span> */}
          {/* {res || `_`} */}
          <span
            // style={{ opacity: 0 }}
            dangerouslySetInnerHTML={{
              __html: value
                ? value.replace(/ /gi, "&nbsp;").replace(/\n$/, "\n&nbsp;")
                : "&nbsp;"
            }}
          />

          {editing && (
            <StyledTextarea
              ref={texareaRef}
              value={value}
              rows={countRows}
              cols={countColumn}
              autoFocus
              fontSize={style.fontSize}
              // cols={res.length}
              onKeyPress={onKeyPress}
              // onChange={e => setRes(e.target.value)}
              onBlur={() => onEdit(false)}
              onChange={onChange}

              // onChange={event => console.log(event.target.value)}
            />
          )}

          {!editing && <StyledWrapper />}

          {/* &nbsp; ewfef&nbsp; */}
        </StyledText>

        {/* {res.length} */}
      </StyledContainer>
    </EditorContentItem>
  );
};

export default EditorContentTextView;
