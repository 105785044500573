export const baseSize = 270;

export const sizes = {
  vertical: {
    width: baseSize * 4,
    height: baseSize * 5
  },

  horizontal: {
    width: baseSize * 5,
    height: baseSize * 4
  },

  square: {
    width: 1080,
    height: 1080
  },

  story: {
    width: 1080,
    height: 1920
  }
  // story: {
  //   width: 1080,
  //   height: 1920
  // },
  // post: {
  //   width: 1080,
  //   height: 1080
  // }

  // horizontal: {
  //   width: 1080,
  //   height: 1350
  // },

  // // post: {
  // //   width: 1080,
  // //   height: 1080
  // // },

  // // vertical: {
  // //   width: baseSize * 4,
  // //   height: baseSize * 5
  // // },

  // // horizontal: {
  // //   width: baseSize * 5,
  // //   height: baseSize * 4
  // // },

  // square: {
  //   width: 1080,
  //   height: 1080
  // }

  // // story: {
  // //   width: baseSize * 4.5,
  // //   height: baseSize * 8
  // // }
};
