import styled from "styled-components";

export const StyledContainer = styled.div`
  /* flex: 1; */
  width: 100%;
  height: 100%;

  background-color: "green";
  background-image: url(${props => props.url});
  background-repeat: norepeat;
  background-size: cover;
`;
