import React from "react";

import EditorHeaderItem from "../item";

import { Select } from "../../../../../../components";

import { StyledContainer } from "./editor-header-scale-styled";

import { from } from "rxjs";

const EditorHeaderScaleView = ({ scale, options, onChange }) => {
  return (
    <>
      <StyledContainer
        as={Select}
        style={{
          color: "#fff",
          border: "none",
          minWidth: 70,
          alignItems: "center",
          display: "flex"
        }}
        value={scale}
        options={options}
        // options={[...options, { value: scale, text: `${scale * 100}%` }]}
        onChange={onChange}
      />
    </>
  );
};

export default EditorHeaderScaleView;
