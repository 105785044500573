import React from "react";

import {
  StyledContainer,
  StyledTitle,
  StyledDescription
} from "./editor-empty-styled";

const EditorEmptyView = () => {
  return (
    <StyledContainer>
      <StyledTitle>Не удалось загрузить данные</StyledTitle>
      <StyledDescription>
        Похоже, что такого шаблона не существует
      </StyledDescription>
    </StyledContainer>
  );
};

export default EditorEmptyView;
