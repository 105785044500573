import styled from "styled-components";

export const StyledContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: #2c2c2c;
`;

export const StyledTitle = styled.h1`
  margin-bottom: 20px;
  color: #fff;
  font-weight: 900;
  font-size: 30px;
`;

export const StyledDescription = styled.p`
  color: #fff;
  font-size: 15px;
`;
