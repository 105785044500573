import React, { useEffect, useMemo, useState } from "react";
import {
  StyledContainer,
  StyledIcon,
  StyledLabel,
  StyledValue,
} from "./input-styled";

import Icon from "../icon";

const InputView = ({
  style,
  value,
  defaultValue,
  icon,
  iconSize = 9,
  min,
  max,
  transformValue,
  label,
  type = "string",
  onChange,
}) => {
  const [localValue, setLocalValue] = useState(
    transformValue ? transformValue(value) : value
  );

  useEffect(() => {
    if (localValue != value)
      setLocalValue(transformValue ? transformValue(value) : value);
  }, [value]);

  const handleChangeValue = (event) => {
    if (!localValue && (defaultValue || value))
      setLocalValue(defaultValue || value);
    if (localValue != value)
      onChange(
        localValue || (!defaultValue && !value)
          ? localValue
          : defaultValue || value
      );
  };

  const handleChange = (value) => {
    if (min != null && max != null) {
      if (value >= min && value <= max)
        setLocalValue(transformValue ? transformValue(value) : value);
    } else setLocalValue(transformValue ? transformValue(value) : value);
  };

  return (
    <StyledContainer style={style}>
      {icon && <Icon src={icon} size={iconSize} />}
      {label && <StyledLabel>{label}</StyledLabel>}

      <StyledValue
        style={{ marginLeft: label || icon ? 10 : 0 }}
        value={localValue}
        type={type}
        fluid
        onChange={(event) => handleChange(event.target.value)}
        onBlur={handleChangeValue}
        onKeyDown={(event) => {
          let allowKeys = ["ArrowUp", "ArrowDown", "Enter"];

          if (allowKeys.indexOf(event.key) != -1) handleChangeValue();
        }}
      />
    </StyledContainer>
  );
};

export default InputView;
