import React from "react";

import {
  StyledContainer,
  StyledTitle,
  StyledDescription
} from "./no-match-styled";

const NoMatchView = () => {
  return (
    <StyledContainer>
      <StyledTitle>404</StyledTitle>
      <StyledDescription>Такой страницы не существует</StyledDescription>
    </StyledContainer>
  );
};

export default NoMatchView;
