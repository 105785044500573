import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import Editor from "../domains/editor";
import NoMatch from "../domains/no-match";
import Test from "../domains/test";

export default () => (
  <Router>
    <Switch>
      {/* <Route path="/">
        <Test />
      </Route> */}

      <Route exact path="/:id">
        <Editor />
      </Route>

      <Route path="*">
        <NoMatch />
      </Route>
    </Switch>
  </Router>
);
