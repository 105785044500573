import { createSelector } from "reselect";

export const rootCommon = state => state.editor;

export const loadingSelector = createSelector(
  rootCommon,
  data => data.loading
);

export const successSelector = createSelector(
  rootCommon,
  data => data.success
);

export const savedSelector = createSelector(
  rootCommon,
  data => data.saved
);

export const defaultScaleSelector = createSelector(
  rootCommon,
  data => data.defaultScale
);

export const scaleSelector = createSelector(
  rootCommon,
  data => data.scale
);

export const canvasSelector = createSelector(
  rootCommon,
  data => data.canvas
);

export const itemsSelector = createSelector(
  rootCommon,
  data => data.items
);

export const historyStackSelector = createSelector(
  rootCommon,
  data => data.historyStack
);

export const selectedItemSelector = createSelector(
  rootCommon,
  data => data.selectedItem
);

export const uploadSelector = createSelector(
  rootCommon,
  data => data.upload
);
