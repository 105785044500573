import styled from "styled-components";

export const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  min-width: 20px;

  transition: all 0.1s;

  cursor: pointer;

  &:hover {
    background: #000000;
  }
`;

export const StyledValue = styled.span`
  color: rgba(255, 255, 255, 0.7);
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
`;
