import styled from "styled-components";

export const StyledContainer = styled.div`
  padding: 0 10px;
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  border: 1px solid #fff;
`;

export const StyledRow = styled.div`
  width: 100%;

  display: flex;
`;

export const StyledItem = styled.div`
  width: 100%;

  padding: 3px 5px;
`;
