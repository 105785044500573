import {
  StyleHeader,
  StyledContainer,
  StyledItem,
  StyledPlus,
  StyledTile,
} from "./editor-control-styled";
import {
  containerStyle,
  elements,
  renderContainer,
  strValuesElements,
} from "../../../../config/elements";

import EditorControlBackground from "./componets/background";
import EditorControlContainer from "./componets/container";
import EditorControlEffects from "./componets/effects";
import EditorControlFill from "./componets/fill";
import EditorControlImage from "./componets/image";
import EditorControlStroke from "./componets/stroke";
import EditorControlStyles from "./componets/styles";
import EditorControlText from "./componets/text";
import EditorControlUploader from "./componets/uploader";
import { Icon } from "../../../../components";
import React from "react";

const EditorControlView = ({
  items,
  selectedItem,
  canvas,
  onChange,
  onRemove,
}) => {
  console.log("strValuesElements", items[selectedItem]);

  return (
    <StyledContainer>
      {/* {selectedItem && items[selectedItem].uploader && (
        <pre style={{ overflow: "scroll" }}>
          {JSON.stringify(items[selectedItem].style)}
        </pre>
      )} */}

      {(items[selectedItem] || selectedItem == null) && (
        <EditorControlContainer
          items={items}
          selectedItem={selectedItem}
          canvas={canvas}
          onChange={onChange}
        />
      )}

      {selectedItem &&
        items[selectedItem] &&
        items[selectedItem].type == "text" && (
          <EditorControlText
            items={items}
            selectedItem={selectedItem}
            onChange={onChange}
          />
        )}

      {(items[selectedItem] || selectedItem == null) &&
        (selectedItem == null
          ? canvas.backgroundColor
          : items[selectedItem].style.backgroundColor ||
            items[selectedItem].style.color) && (
          <EditorControlFill
            items={items}
            selectedItem={selectedItem}
            canvas={canvas}
            onChange={onChange}
          />
        )}

      {items[selectedItem] &&
        items[selectedItem].containerStyle.backgroundColor &&
        items[selectedItem].type == "text" && (
          <EditorControlBackground
            items={items}
            selectedItem={selectedItem}
            canvas={canvas}
            onChange={onChange}
          />
        )}

      {((selectedItem &&
        items[selectedItem] &&
        (items[selectedItem].type == "image" ||
          items[selectedItem].type == "upload")) ||
        selectedItem == null) && (
        <EditorControlImage
          items={items}
          selectedItem={selectedItem}
          canvas={canvas}
          onChange={onChange}
        />
      )}

      {selectedItem && items[selectedItem].type == "upload" && (
        <EditorControlUploader
          items={items}
          selectedItem={selectedItem}
          canvas={canvas}
          onChange={onChange}
        />
      )}

      {selectedItem && (
        <>
          <EditorControlStroke
            items={items}
            selectedItem={selectedItem}
            onChange={onChange}
            onRemove={onRemove}
          />

          <EditorControlEffects
            items={items}
            selectedItem={selectedItem}
            onChange={onChange}
            onRemove={onRemove}
          />
        </>
      )}
    </StyledContainer>
  );
};

export default EditorControlView;
