import styled from "styled-components";

export const StyledContainer = styled.div`
  padding: 5px;
  width: 100%;

  display: flex;
  align-items: center;

  font-size: 12px;

  border: 1px solid #fff;
  border-radius: 3px;

  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
`;
