import React from "react";

import { Icon, Drawable } from "../../../../../../components";

import EditorContentItem from "../item";

// import { useValidStyle } from "../../../../../../hook";

import {
  StyledContainer,
  StyledUploader
} from "./editor-content-upload-styled";

const EditorContentUploadView = ({
  selected,
  style,
  src,
  containerStyle,
  uploader,
  activeItem,
  mousePressedUploader,
  onMouseDown,
  onChangeActiveItem,
  onMouseDownUploader,
  onMouseUpUploader,
  onMouseMoveUploader
}) => {
  // const validStyle = useValidStyle(style);

  return (
    <EditorContentItem
      selected={selected}
      style={containerStyle}
      onMouseDown={() => !activeItem && onMouseDown()}
    >
      {/* <Drawable onChangeUploadMoving={onChangeUploadMoving}> */}

      {/* <p>{JSON.stringify(style)}</p> */}
      <StyledContainer
        style={style}
        src={src}
        // onMouseDown={onMouseDownUploader}
        // onMouseUp={onMouseUpUploader}
        // onMouseMove={event =>
        //   mousePressedUploader && activeItem && onMouseMoveUploader(event)
        // }
      >
        <StyledUploader
          {...uploader}
          selected={selected}
          activeItem={activeItem}
          // onMouseDown={() => {
          //   onChangeActiveItem(true);
          // }}
        >
          {uploader && <Icon name="plus" size={uploader.height / 4} />}
        </StyledUploader>
      </StyledContainer>
      {/* </Drawable> */}
    </EditorContentItem>
  );
};

export default EditorContentUploadView;
