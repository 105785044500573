import styled from "styled-components";

export const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  border: 1px solid #fff;
`;

export const StyledItem = styled.div`
  margin: 3px 10px;
  min-width: 30%;

  flex: 1;
`;
