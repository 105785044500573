import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import EditorView from "./editor-view";
import { NotificationManager } from "react-notifications";

const EditorContainer = props => {
  let { id } = useParams();
  let query = new URLSearchParams(useLocation().search);

  useEffect(() => {
    props.loadEditorData({ id, token: query.get("token") });
  }, []);

  useEffect(() => {
    if (props.upload.success === false) {
      NotificationManager.error(
        "попробуйте позже",
        "Что-то пошло не так",
        4000
      );
    } else if (props.upload.success) {
      NotificationManager.success("Шаблон сохранен", "Success");
    }
  }, [props.upload.success]);

  return <EditorView {...props} />;
};

export default EditorContainer;
