import { StyledContainer, StyledWrraper } from "./editor-content-line-styled";

import EditorContentItem from "../item";
import React from "react";
import { useValidStyle } from "../../../../../../hook";

const EditorContentImageView = ({
  src,
  scale,
  selected,
  style,
  containerStyle,
  onMouseDown,
  onClick
}) => {
  return (
    <EditorContentItem
      selected={selected}
      style={containerStyle}
      onMouseDown={onMouseDown}
    >
      <StyledContainer style={style} />
    </EditorContentItem>
  );
};

export default EditorContentImageView;
