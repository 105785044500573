import React from "react";

import { Button } from "semantic-ui-react";

import EditorHeaderItem from "./componets/item";
import EditorHeaderItemImage from "./componets/itemImage";
import EditorHeaderScale from "./componets/scale";

import {
  StyledContainer,
  StyledGroup,
  StyledButton
} from "./editor-header-styled";

const EditorHeaderView = ({
  defaultScale,
  scale,
  upload,
  onAddItem,
  onChangeScale,
  onUpload
}) => {
  return (
    <StyledContainer>
      <StyledGroup>
        <EditorHeaderItem
          icon={"block"}
          onClick={value => {
            onAddItem({
              type: "block",
              value
            });
          }}
        />

        <EditorHeaderItem
          icon={"text"}
          onClick={() => {
            onAddItem({ type: "text" });
          }}
        />

        <EditorHeaderItem
          icon={"line"}
          onClick={value => {
            onAddItem({
              type: "line",
              value
            });
          }}
        />

        <EditorHeaderItemImage
          icon={"image"}
          onClick={value => {
            onAddItem({
              type: "image",
              value
            });
          }}
        />

        <EditorHeaderItem
          icon="plus"
          onClick={value => {
            onAddItem({
              type: "upload",
              value
            });
          }}
        />
      </StyledGroup>

      <StyledGroup>
        <StyledButton>
          <Button
            size="mini"
            loading={upload.loading}
            primary
            onClick={onUpload}
          >
            Save
          </Button>
        </StyledButton>

        <EditorHeaderScale
          defaultScale={defaultScale}
          scale={scale}
          onChange={onChangeScale}
        />
      </StyledGroup>
    </StyledContainer>
  );
};

export default EditorHeaderView;
