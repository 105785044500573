import { StyledContainer, StyledImage, StyledWrapper } from "./icon-styled";

import PropTypes from "prop-types";
import React from "react";
import { icons } from "./icon-config";

const Icon = ({ name, src, size, color, style, onClick }) => {
  return (
    (!!src || icons[name]) && (
      <StyledContainer onClick={onClick}>
        <StyledImage
          style={style}
          src={src || icons[name]}
          size={size}
          color={color}
        />

        <StyledWrapper />
      </StyledContainer>
    )
  );
};

Icon.propTypes = {
  name: PropTypes.string,
  size: PropTypes.number,
  color: PropTypes.string,
};

Icon.defaultProps = {
  size: 24,
  color: null,
};

export default Icon;
