import { Input, Select } from "../../../../../../components";
import React, { useMemo } from "react";
import { StyledContainer, StyledItem } from "./editor-control-container-styled";
import {
  containerStyle,
  elements,
  renderContainer,
  strValuesElements,
} from "../../../../../../config/elements";
import { countColumnOptions, typeOptions } from "./options";

import EditorControlContainerItem from "./componets/item";
import EditorControlItemContainer from "../root/itemContainer";

const EditorControlContainerView = ({
  items,
  selectedItem,
  canvas,
  onChange,
}) => {
  const data = useMemo(() => {
    let res = Object.keys({ ...renderContainer });

    if (selectedItem && items[selectedItem].type == "text") {
      return ["left", "top", "transform", "zIndex"];
    } else if (selectedItem == null) {
      return ["width", "height"];
    }

    return res;
  }, [renderContainer, selectedItem]);

  return (
    <EditorControlItemContainer
      title="Container"
      content={
        <StyledContainer>
          {selectedItem != null ? (
            data.map((key) => (
              <EditorControlContainerItem
                name={key}
                label={renderContainer[key]}
                key={key}
                tag={
                  items[selectedItem].containerStyle[key] ||
                  key == "zIndex" ||
                  key == "top" ||
                  key == "left"
                    ? "containerStyle"
                    : "style"
                }
                type={
                  strValuesElements.indexOf(key) != -1 ? "string" : "number"
                }
                value={
                  items[selectedItem].style[key] ||
                  items[selectedItem].containerStyle[key]
                }
                onChange={onChange}
              />
            ))
          ) : (
            <div style={{ display: "flex", width: "100%" }}>
              <StyledItem>
                <Select
                  value={canvas.type}
                  search
                  style={{ fontSize: 11.5 }}
                  options={typeOptions}
                  onChange={(value) =>
                    onChange({
                      key: "type",
                      type: "canvas",
                      value: value,
                    })
                  }
                />
              </StyledItem>

              <StyledItem>
                <Select
                  value={canvas.countColumn}
                  search
                  style={{ fontSize: 11.5 }}
                  options={countColumnOptions}
                  onChange={(value) =>
                    onChange({
                      key: "countColumn",
                      type: "canvas",
                      value: value,
                    })
                  }
                />
              </StyledItem>
            </div>
          )}

          {/* data.map((key) => (
                <EditorControlContainerItem
            name={key}
            label={renderContainer[key]}
            key={key}
            tag={"canvas"}
            type={
              strValuesElements.indexOf(key) != -1 ? "string" : "number"
            }
            value={canvas[key]}
            onChange={(value) => {
              console.log(value);
              onChange(value);
            }}
          />
              )) */}
        </StyledContainer>
      }
    />
  );
};

export default EditorControlContainerView;
