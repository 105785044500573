import styled from "styled-components";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  background: #2c2c2c;
`;

export const StyledTitle = styled.p`
  margin-bottom: 18px;

  text-align: center;
  font-size: 50px;
  font-weight: 900;
  color: #fff;
`;
export const StyledDescription = styled.p`
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: rgba(255, 255, 255, 1);
`;
