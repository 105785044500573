import styled from "styled-components";

export const StyledBorder = styled.div`
  position: absolute;
  top: ${props => (!props.horizontal ? "-6px" : "auto")};
  ${props => props.position}: ${props =>
  props.inside ? (props.selected || props.hovered ? 0 : 0) : -6}px;
  
  height: ${props => (props.horizontal ? "10px" : "calc(100% + 12px)")};
  width: ${props => (!props.horizontal ? "10px" : "100%")};
  /* right: ${props => (props.inside ? (props.selected ? 6 : 6) : 0)}px; */
  /* bottom: ${props => (props.inside ? (props.selected ? 0 : 0) : 0)}px; */
  /* left: ${props => (props.inside ? (props.selected ? 6 : 6) : 0)}px; */

  border-${props => props.position}: ${props =>
  props.selected || props.hovered ? 6 : 0}px ${props =>
  props.selected ? "dashed" : "solid"} #4b9ef4;

  /* box-shadow: 0 0 10px rgba(0,0,0,0.5); */

  z-index: 99999;
`;

export const StyledContainer = styled.div`
  position: absolute;

  box-sizing: border-box;

  ${props => props.inside && `z-index: -1`};

  /* width: 300px;
  height: 300px;

  background: green; */
`;

export const StyledContent = styled.div`
  position: relative;

  // width: 100%;
  // height: 100%;
`;
