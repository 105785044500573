import styled from "styled-components";

export const StyledContainer = styled.div`
  width: 250px;

  // display: flex;
  // flex-wrap: wrap;
  // align-items: flex-start;

  background: #ffff;
`;

export const StyledItem = styled.div`
  padding: 15px 0;

  // margin-bottom: 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  border-bottom: 1px solid #e5e5e5;
`;

export const StyleHeader = styled.div`
  width: 100%;

  margin: 0;
  margin-bottom: 10px;
  padding: 0 15px;

  display: flex;
  justify-content: space-between;
`;

export const StyledTile = styled.p`
  font-weight: 700;
  font-size: 11px;
  color: #333;
`;

export const StyledPlus = styled.div`
  transition: all 0.2s;

  &:hover {
    transition: all 0.2s;
    opacity: 0.5;
  }
`;
