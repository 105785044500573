import React, { useEffect, useMemo, useState } from "react";
import {
  StyledContainer,
  StyledContent,
  StyledItem,
} from "./edit-control-text-align-styled";

import { Icon } from "../../../../../../../../components";

const EditoControlTextAlignView = ({ value, onChange }) => {
  return (
    <StyledContainer>
      <StyledContent>
        <StyledItem onClick={() => onChange("left")} active={value == "left"}>
          <Icon src={require("./image/left.svg")} size={14} />
        </StyledItem>

        <StyledItem
          onClick={() => onChange("center")}
          active={value == "center"}
        >
          <Icon src={require("./image/center.svg")} size={14} />
        </StyledItem>

        <StyledItem onClick={() => onChange("right")} active={value == "right"}>
          <Icon src={require("./image/right.svg")} size={14} />
        </StyledItem>
      </StyledContent>
    </StyledContainer>
  );
};

export default EditoControlTextAlignView;
