import React, { createRef, useState } from "react";
import {
  StyledContainer,
  StyledFileLoad,
} from "./editor-header-item-image-styled";

import { FileLoader } from "../../../../../../components";
import Item from "../item";

const EditorHeaderItemImageView = ({ icon, onClick }) => {
  return (
    <StyledContainer>
      <FileLoader onClick={onClick}>
        <Item icon={icon} />
      </FileLoader>
    </StyledContainer>
  );
};

export default EditorHeaderItemImageView;
