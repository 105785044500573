import request from "../../utils/request";

export const loadEditorData = ({ id, token }) =>
  request.get(`get/preset/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const uploadTemplate = ({ form, id, token }) =>
  request.post(`update/preset/${id}`, form, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  });
